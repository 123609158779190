<template>
    <div class="container">
        <Header @collpase="changeCollapse"/>
        <div class="big">
            <el-aside :width="changeWidth" :style="{height: scrollHeight,overflowY: 'auto'}">
                <div class="home" @click="checkHome">
                    <i class="iconfont1">&#xe639;</i>
                    <div style="margin-left:20px">首页</div>
                </div>
                <el-menu default-active="1-4-1"
                         class="el-menu-vertical-demo"
                         :default-active="onRoutes"
                         :collapse="isCollapse"
                         text-color="#4D4D4D"
                         active-text-color="#ffffff"
                         background-color="#ffffff"
                         @open="handleOpen"
                         @close="handleClose"
                         style="text-align: left;cursor: pointer;"
                         unique-opened
                         router>
                    <el-submenu :index="item.id" v-for="item in menus" :key="item.id"
                                style="background-color: #FFFFFF;">
                        <template slot="title">
                            <i :class="item.iconPath" :style="{'margin-right':item.menuName=='系统管理'?'13px':''}"></i>
                            <span slot="title">{{item.menuName}}</span>
                        </template>
                        <el-menu-item :index="value.menuUrl" v-for="(value, index) in item.childrenList" :key="index">
                            <template slot="title">
                                <!--<i :class=item.icon"></i>-->
                                <i class="iconfont"
                                >{{value.iconPath}}
                                </i>
                                <span slot="title" style="margin-left: 20px;">{{value.menuName}}</span>
                            </template>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <div class="main">
                <Tags/>
                <div class="content">
                    <keep-alive>
                        <router-view/>
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from "../components/common/js/bus.js"

    export default {
        data() {
            return {
                menus: [],
                //collapse: false,
                tagsList: [],
                isCollapse: false,
                changeWidth: '10%',
                scrollHeight: ''
            }
        },
        methods: {
            checkHome() {
                var roleName = localStorage.getItem("roleName");
                if (roleName == 'admin'
                    || roleName == 'manager'
                    || roleName == 'finance_supervisor'
                    || roleName == 'production_supervisor'
                    || roleName == 'product_supervisor'
                    || roleName == 'operations_supervisor'){
                    this.$router.push('/bossIndex')
                }else {
                    this.$router.push('/index')
                }
            },
            changeCollapse(e) {
                if (e == true) {
                    this.changeWidth = '3.5%';
                } else {
                    this.changeWidth = '11%';
                }
                this.isCollapse = e;
            },
            handleOpen(key, keyPath) {
            },
            handleClose(key, keyPath) {
            },
            isActive(href) {
                return href === this.$route.fullPath
            },
            closeTags(index) {
                var delItem = this.tagsList.splice(index, 0)[1];
                var item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
                if (item) {
                    delItem.href === this.$route.fullPath && this.$router.push(item.href);
                } else {
                    this.$router.push("/")
                }
            },
            setTags(route) {
                var isExist = this.tagsList.some(item => {
                    return item.href === route.fullPath;
                })
                if (!isExist) {
                    if (this.tagsList.length > 0) {
                        this.tagsList.shift()
                    }
                    this.tagsList.push({
                        title: this.name,
                        href: this.fullPath,
                        name: route.matched[1].components.default.name
                    })
                }
                bus.$emit('tags', this.tagsList);
            }
        },
        created() {
            this.scrollHeight = (window.innerHeight)*0.92 + "px"
            this.setTags(this.$route)
            this.$api.getMenu().then(
                res => {
                    for (var i = 0; i < res.data.menuTree.length; i++) {
                        res.data.menuTree[i].id = res.data.menuTree[i].id + ''
                        res.data.menuTree[i].iconPath = "iconfont " + res.data.menuTree[i].iconPath
                    }
                    var menuTrees = res.data.menuTree
                    this.menus = menuTrees;
                    localStorage.setItem("authorityList", res.data.userInfo.roles[0].permissions);
                }
            ).catch(res => {
            });
        },
        computed: {
            onRoutes() {
                return this.$route.path.replace('/', '')
            },
            showTags() {
                return this.tagsList.length > 0;
            }
        },
        watch: {
            $route(newValue, oldValue) {
                this.setTags(newValue)
            },
        }
    }

</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 17px !important;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 47%;
        margin-right: 20px;
    }

    .iconfont1 {
        font-family: "iconfont" !important;
        font-size: 17px;
        font-style: normal;
        color: #999999;
    }

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .big {
        flex: 1;
        display: flex;
    }

    .home {
        height: 56px;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #4D4D4D;
        font-size: 15px;
        cursor: pointer;
    }

    .home:hover {
        background-color: #ccc;
    }

    .el-aside {
        position: relative;
        min-height: 100%;
        display: block;
        background-color: #FFFFFF;
        overflow: hidden;
        width: 11% !important;
    }

    .el-submenu__title * {
        font-size: 15px;
    }

    .el-submenu {
        background-color: #FFFFFF;
    }

    .main {
        height: 100%;
        width: 89% !important;
        flex: 1;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: #F0F2F5;

    }

    .content {
        padding: 5px;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .tags {
        position: relative;
        height: 30px;
        overflow: hidden;
        group-reset: #fff;
        padding-right: 120px;
        box-shadow: 0 5px 10px #ddd;
    }

    .tags ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }

    .tags li {
        float: left;
        margin: 3px 5px 2px 3px;
        border-radius: 3px;
        font-size: 12px;
        overflow: hidden;
        cursor: pointer;
        height: 23px;
        line-height: 23px;
        border: 1px solid #e9eaec;
        background: #FFFFFF;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all .3s ease-in;
        -moz-transition: all .3s ease-in;
        transition: all .3s ease-in;
    }

    .tags li:not(.active):hover {
        background: #f8f8f8;
    }

    .tags .active {
        color: #4D4D4D;
    }

    .tags-li-title {
        float: left;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
        color: #666;

        tags-li.active .tags-li-title {
            color: #4D4D4D;
        }
    }
</style>
